import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import {
  MedWrapper,
  headlineOne,
  headlineTwo,
  bodyCopy,
  striptCallOut,
} from "../../../Utilities"

const MainLayoutStyled = styled.section`
  .wrapper {
    ${MedWrapper};
  }

  .bio-meta {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(33.3333% - 2rem);
      margin-right: 2rem;
    }
  }

  .bio-image {
    max-width: 35rem;
    margin-right: 0;
    margin-left: auto;
  }

  .bio-whoAmI {
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;

    @media (min-width: 768px) {
      margin-bottom: 0;
      text-align: right;
    }

    ul {
      li {
        ${bodyCopy};
        color: #585858;
      }
    }
  }

  .bio-content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(66.6666% - 2rem);
      margin-left: 2rem;
    }
  }

  .bio-titles {
    width: 100%;
    margin-bottom: 5rem;

    h1 {
      ${headlineOne};
      margin: 0;
      text-transform: uppercase;
    }

    h3 {
      ${headlineTwo};
      margin: 0;
    }
  }

  .bio-information {
    p {
      ${bodyCopy};
      color: #585858;
    }
  }

  .bio-core {
    margin-top: 5rem;

    p {
      ${striptCallOut};
      color: #585858;
      text-align: left;
    }
  }
`

const MainLayout = ({ bioImg, whoYouAre, name, title, bioContent, value }) => {
  return (
    <MainLayoutStyled>
      <div className="wrapper">
        <div className="bio-meta">
          <div className="bio-image">
            <Img fluid={bioImg} alt={name} />
          </div>
          <div className="bio-whoAmI">
            <ul>
              {whoYouAre.map((item, index) => {
                return <li key={index}>{item.point}</li>
              })}
            </ul>
          </div>
        </div>
        <div className="bio-content">
          <div className="bio-titles">
            <h1>{name}</h1>
            <h3>{title}</h3>
          </div>
          <div
            className="bio-information"
            dangerouslySetInnerHTML={{ __html: bioContent }}
          />
          <div className="bio-core">
            <p>Core Value closest to heart: {value}</p>
          </div>
        </div>
      </div>
    </MainLayoutStyled>
  )
}

export default MainLayout
