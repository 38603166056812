import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/Seo/seo"

import Hero from "../components/PageParts/SingleTeam/Hero"
import MainLayout from "../components/PageParts/SingleTeam/MainLayout"

const team = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={`${props.data.member.acf._swb_name} - Team Member at Switchback Creative.`}
        description={`${props.data.member.acf._swb_name} - ${props.data.member.acf._swb_title}`}
        location={props.location.pathname}
      />
      <Hero
        img={
          props.data.member.acf._swb_hero_image.localFile.childImageSharp.fluid
        }
      />
      <MainLayout
        bioImg={
          props.data.member.acf._swb_image.localFile.childImageSharp.fluid
        }
        whoYouAre={props.data.member.acf._swb_who_you_are}
        name={props.data.member.acf._swb_name}
        title={props.data.member.acf._swb_title}
        bioContent={props.data.member.acf._swb_bio}
        value={props.data.member.acf._swb_core_value}
      />
    </Layout>
  )
}

export const query = graphql`
  query teamQuery($slug: String!) {
    member: wordpressWpSwbTeam(slug: { eq: $slug }) {
      title
      acf {
        _swb_name
        _swb_title
        _swb_core_value
        _swb_bio
        _swb_hero_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        _swb_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        _swb_who_you_are {
          point
        }
      }
    }
  }
`

export default team
